@import "assets/styles/main.scss";
.booking {
    &-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $grey;
        height: 100%;
    }
}
