@import "assets/styles/main.scss";
@import '~rsuite/dist/rsuite.min.css';

.booking {
    &__timeline {
        &-wrapper {
            background-color: $white;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background-color: $white;
            box-shadow: 0 -2px 10px 0 rgba($black, 0.2);
            border-top: 1px solid $grey;
            width: calc(100%);
            padding: 55px;
        }
    }
}
